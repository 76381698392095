import styled from 'styled-components';

export const HorizontalScrollWrapper = styled.div`
    overflow: scroll;
    padding-bottom: 4px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;
