import {ISpacings} from '../../types';
import Row from '@components/row';
import spacings from '@constants/spacings';
import {Body, Subline} from '@components/text';
import {ReactComponent as ICArrowLeft} from '@icons/ic-arrow-left.svg';
import {ReactComponent as ICArrowRight} from '@icons/ic-arrow-right.svg';
import OptionTile from '@components/option-tile';
import {ISelect} from './types';
import {useState} from 'react';
import {HorizontalScrollWrapper} from './styled';
import {calculateSpacings} from '@/common/helpers';

const Select = ({
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
    title,
    decription,
    options,
    selected,
    imageSize,
}: ISpacings & ISelect) => {
    const [_selected, _setSelected] = useState<string>(selected);

    return (
        <div
            style={{
                ...calculateSpacings({mt, mr, mb, ml, pt, pr, pb, pl}),
            }}>
            <Row
                mb={spacings.xs}
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <Subline quaternary style={{lineHeight: 1}}>
                    {title}
                </Subline>
                <Row style={{gap: spacings.m}} mr={spacings.l - spacings.xxxs}>
                    <ICArrowLeft />
                    <ICArrowRight />
                </Row>
            </Row>
            <HorizontalScrollWrapper>
                <Row style={{gap: spacings.xxxs, alignItems: 'stretch'}}>
                    {options.map(option => (
                        <OptionTile
                            title={option.name}
                            description={option.description}
                            price={option.price}
                            image={option.image}
                            icon={option.icon}
                            imageHeight={imageSize === 'small' ? 90 : 150}
                            selectedPrice={
                                options.find(
                                    option => option.value === _selected,
                                ).price
                            }
                            selected={option.value === _selected}
                            onClick={() => _setSelected(option.value)}
                        />
                    ))}
                </Row>
            </HorizontalScrollWrapper>
            <Body
                mt={spacings.xxs}
                mr={spacings.l - spacings.xxxs}
                size="small">
                {decription}
            </Body>
        </div>
    );
};

export default Select;
