import {ISpacings} from '../../types';
import Row from '@components/row';
import spacings from '@constants/spacings';
import {Body, Headline, Subline} from '@components/text';
import {ReactComponent as ICArrowLeft} from '@icons/ic-arrow-left.svg';
import {ReactComponent as ICArrowRight} from '@icons/ic-arrow-right.svg';
import {ISlider} from './types';
import {useState} from 'react';
import {calculateSpacings} from '@/common/helpers';
import {StyledRangeInput} from './styled';

const Slider = ({
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
    title,
    decription,
    options,
    selectedIndex,
}: ISpacings & ISlider) => {
    const [_selectedIndex, _setSelectedIndex] = useState<number>(selectedIndex);

    return (
        <div
            style={{
                ...calculateSpacings({mt, mr, mb, ml, pt, pr, pb, pl}),
            }}>
            <Row
                mb={spacings.xs}
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <Subline quaternary style={{lineHeight: 1}}>
                    {title}
                </Subline>
                <Row style={{gap: spacings.m}} mr={spacings.l - spacings.xxxs}>
                    <ICArrowLeft />
                    <ICArrowRight />
                </Row>
            </Row>
            <div style={{marginRight: spacings.l - spacings.xxxs}}>
                <Row style={{justifyContent: 'space-between', width: '100%'}}>
                    {options.map((option, index) => (
                        <div style={{width: 25, textAlign: 'center'}}>
                            {options[_selectedIndex].value === option.value ? (
                                <Headline quaternary>{option.name}</Headline>
                            ) : (
                                <Body>{option.name}</Body>
                            )}
                        </div>
                    ))}
                </Row>
                <StyledRangeInput
                    type="range"
                    min="0"
                    max={options.length - 1}
                    value={_selectedIndex}
                    onChange={event => {
                        _setSelectedIndex(Number(event.target.value));
                    }}
                />
            </div>
            <Body
                mt={spacings.xxs}
                mr={spacings.l - spacings.xxxs}
                size="small">
                {decription}
            </Body>
        </div>
    );
};

export default Slider;
