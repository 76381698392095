import React from 'react';
import colors from '@/assets/constants/colors';
import { Body, Headline, Subline } from '@/common/components/text';
import { Wrapper } from './styled';
import Button from '@/common/components/button';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';

const BottomBar = () => {
    return <Wrapper>
        <div>

        </div>
        <Row>
            <Row style={{alignItems: 'flex-end'}}>
                <Subline tertiary color={colors.White} style={{lineHeight: 1}}>
                    Summe: <b style={{lineHeight: 1}}>10.000,00 €</b>
                </Subline>
                <Body color={colors.White} style={{lineHeight: 1, marginBottom: 2, marginLeft: spacings.xxxs}}>
                    inkl. MwSt.
                </Body>
            </Row>
            <Button 
                label='Nächster Schritt' 
                ml={spacings.xs}
            />
        </Row>
    </Wrapper>;
}

export default BottomBar;