import styled from 'styled-components';
import Spacings from '../../../assets/constants/spacings';

export const StyledButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    padding-left: ${Spacings.xs}px;
    padding-right: ${Spacings.xs}px;
    border-radius: 2px;
    border-width: 3px;
    border-style: solid;
    outline: none;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        /* TODO opacity is buggy on Safari (?) so we use filter instead */
        /* opacity: 0.6; */
        filter: opacity(0.6);
    }

    &:disabled {
        pointer-events: none;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
`;