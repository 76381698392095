import {ISpacings} from '../../types';
import spacings from '@constants/spacings';
import {Body} from '@components/text';
import {IColorPicker} from './types';
import {useState} from 'react';
import {calculateSpacings, formatPrice} from '@/common/helpers';
import {ColorTile, DiagonaleLine, DiagonaleLineAlt} from './styled';
import Row from '@components/row';
import {ReactComponent as ICCheck} from '@icons/ic-check.svg';
import colors from '@constants/colors';

const ColorPicker = ({
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
    title,
    decription,
    options,
    selected,
}: ISpacings & IColorPicker) => {
    const [_selected, _setSelected] = useState<string>(selected);

    return (
        <div
            style={{
                ...calculateSpacings({mt, mr, mb, ml, pt, pr, pb, pl}),
            }}>
            <Body size="large" mb={spacings.xxs}>
                {title}
            </Body>
            <Row
                style={{
                    gap: 2,
                    flexWrap: 'wrap',
                    marginRight: spacings.l - spacings.xxxs,
                }}>
                {options.map(option => (
                    <div style={{position: 'relative', overflow: 'hidden'}}>
                        <DiagonaleLine />
                        <DiagonaleLineAlt />
                        <ColorTile
                            style={{
                                backgroundColor: option.value,
                                borderWidth: option.value === _selected ? 2 : 1,
                                borderColor:
                                    option.value === _selected
                                        ? colors.Black
                                        : colors.Grey,
                            }}
                            onClick={() => _setSelected(option.value)}>
                            {option.value === _selected && (
                                <div
                                    style={{
                                        backgroundColor: colors.Black,
                                        width: 20,
                                        height: 20,
                                        borderRadius: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <ICCheck />
                                </div>
                            )}
                        </ColorTile>
                    </div>
                ))}
            </Row>
            <Row
                style={{
                    justifyContent: 'space-between',
                    marginRight: spacings.l - spacings.xxxs,
                    marginTop: spacings.xxs,
                }}>
                <Body size="small" bold>
                    {options.find(option => option.value === _selected).name}
                </Body>
                <Body size="small">
                    +
                    {formatPrice(
                        options.find(option => option.value === _selected)
                            .price,
                    )}
                </Body>
            </Row>
            <Body
                mt={spacings.xxxs}
                mr={spacings.l - spacings.xxxs}
                size="small">
                {decription}
            </Body>
        </div>
    );
};

export default ColorPicker;
