import colors from '@/assets/constants/colors';
import * as Helpers from '../../helpers';
import { IGenericElement, ISpacings } from '../../types';
import { Body } from "../text";
import { Row, StyledButton } from './styled';
import { IButton } from './types';

const Button = ({
    label,
    small,
    primary,
    secondary,
    tertiary,
    disabled,
    style,
    icon,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    onClick,
    round,
    noPadding,
    iconRight,
    children,
    loading
}: IButton & ISpacings & IGenericElement) => {
    const loaderSize = small ? 12 : 20;
    const size = {
        height: small ? 40 : 48,
    } as const;
    const styles = {
        color: colors.White,
        backgroundColor: colors.Aqua,
        borderColor: colors.Aqua,
    } as const;

    return (
        <StyledButton
            onClick={onClick}
            style={{
                ...size,
                ...styles,
                ...Helpers.calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
                ...round ? { borderRadius: 100 } : {},
                ...noPadding ? { padding: 0 } : {},
                ...tertiary ? { borderWidth: 0 } : {},
                ...style,
            }}
            disabled={disabled}
        >
            <Row style={{ opacity: loading ? 0 : 1 }}>
                {icon && icon}
                <Body bold>{label}</Body>
                {iconRight && iconRight}
                {children}
            </Row>
        </StyledButton>
    );
}

export default Button;