import styled from 'styled-components';
import { calculateSpacings } from '@/common/helpers';
import { IGenericElement, ISizes, ISpacings } from '@/common/types';

const StyledText = styled.h2`
    font-family: Asap;
    line-height: 1.5;

    b {
        font-weight: bold;
    }
`;

const Subline = ({
    children,
    style,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    primary,
    secondary,
    tertiary,
    quaternary,
    nowrap,
    color
}: IGenericElement & ISpacings & ISizes & { nowrap?: boolean; color?: string; }) => {
    return (
        <StyledText style={{
            ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
            ...{ fontSize: primary ? '3rem' : secondary ? '2.25rem' : tertiary ? '1.75rem' : quaternary ? '1.5rem' : '3rem' },
            ...nowrap ? { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' } : {},
            ...color ? { color: color } : {},
            ...style,
        }}>
            {children}
        </StyledText>
    );
}

export default Subline;