export const Black = '#38404D';
export const White = '#FFFFFF';
export const Aqua = '#5CC3CC';
export const Grey = '#E7EBF0';
export const LightGrey = '#F2F4F6';
export const MiddleGrey = '#C2C9D2';

export default {
    Black,
    White,
    Aqua,
    Grey,
    LightGrey,
    MiddleGrey,
};
