import React from 'react';
import BottomBar from './bottom-bar';
import NavigationBar from './navigation-bar';
import PreviewPanel from './preview-panel';
import ProductPanel from './product-panel';
import { Wrapper } from './styled';

const Configurator = () => {
    return <Wrapper>
        <NavigationBar />
        <ProductPanel />
        <PreviewPanel />
        <BottomBar />
    </Wrapper>;
}

export default Configurator;