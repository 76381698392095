import React from 'react';
import {ReactComponent as Logo} from '@images/siwing-logo.svg';
import {ReactComponent as ICRotor} from '@icons/ic-rotor.svg';
import {ReactComponent as ICMast} from '@icons/ic-mast.svg';
import {ReactComponent as ICBase} from '@icons/ic-base.svg';
import {ReactComponent as ICColor} from '@icons/ic-color.svg';
import {ReactComponent as ICSettings} from '@icons/ic-settings.svg';
import {ReactComponent as ICDelivery} from '@icons/ic-delivery.svg';
import {ReactComponent as ICSummary} from '@icons/ic-summary.svg';
import { IconWrapper, Wrapper } from './styled';

const NavigationBar = () => {
    return <Wrapper>
        <Logo />
        <IconWrapper>
            <ICRotor />
        </IconWrapper>
        <IconWrapper>
            <ICMast />
        </IconWrapper>
        <IconWrapper>
            <ICRotor />
        </IconWrapper>
        <IconWrapper>
            <ICBase />
        </IconWrapper>
        <IconWrapper>
            <ICColor />
        </IconWrapper>
        <IconWrapper>
            <ICSettings />
        </IconWrapper>
        <IconWrapper>
            <ICDelivery />
        </IconWrapper>
        <IconWrapper>
            <ICSummary />
        </IconWrapper>
    </Wrapper>;
}

export default NavigationBar;