import {calculateSpacings} from '@/common/helpers';
import {IGenericElement, ISpacings} from '@/common/types';
import styled from 'styled-components';
import {ITextStyles} from '../types';

const StyledText = styled.p`
    font-family: Asap;
    line-height: 1.5;

    b {
        font-weight: bold;
    }
`;

const Body = ({
    children,
    style,
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
    bold,
    italic,
    color,
    size,
    link,
    onClick,
}: IGenericElement & ISpacings & ITextStyles) => {
    return (
        <StyledText
            onClick={onClick}
            style={{
                ...calculateSpacings({mt, mr, mb, ml, pt, pr, pb, pl}),
                ...{
                    fontWeight: bold ? 'bold' : 'normal',
                    fontStyle: italic ? 'italic' : 'normal',
                },
                ...(color ? {color: color} : {}),
                ...(size === 'large'
                    ? {fontSize: '1.125rem'}
                    : size === 'small'
                    ? {fontSize: '0.8125rem'}
                    : {fontSize: '1rem'}),
                ...style,
            }}>
            {children}
        </StyledText>
    );
};

export default Body;
