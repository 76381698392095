import {PRODUCT_PANEL_WIDTH} from '@/assets/constants/constants';
import spacings from '@/assets/constants/spacings';
import colors from '@constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
    padding-top: ${spacings.xl}px;
    padding-bottom: ${spacings.xxxl}px;
    padding-left: ${spacings.l}px;
    padding-right: ${spacings.xxxs}px;
    background-color: ${colors.White};
    width: ${PRODUCT_PANEL_WIDTH}px;
    max-height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;
