import spacings from '@/assets/constants/spacings';
import colors from '@constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: ${spacings.xxs}px;
    padding-bottom: 80px;
`;
