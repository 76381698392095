import styled from 'styled-components';

export const StyledRangeInput = styled.input`
    height: 30px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #c2c9d2;
        border-radius: 6px;
        border: 0px solid #000000;
    }

    &::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background: #38404d;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -9px;
    }

    &:focus::-webkit-slider-runnable-track {
        background: #c2c9d2;
    }

    &::-moz-range-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #c2c9d2;
        border-radius: 6px;
        border: 0px solid #000000;
    }

    &::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background: #38404d;
        cursor: pointer;
    }

    &::-ms-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: #c2c9d2;
        border: 0px solid #000000;
        border-radius: 12px;
        box-shadow: 0px 0px 0px #000000;
    }

    &::-ms-fill-upper {
        background: #c2c9d2;
        border: 0px solid #000000;
        border-radius: 12px;
        box-shadow: 0px 0px 0px #000000;
    }

    &::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background: #38404d;
        cursor: pointer;
    }

    &:focus::-ms-fill-lower {
        background: #c2c9d2;
    }

    &:focus::-ms-fill-upper {
        background: #c2c9d2;
    }
`;
