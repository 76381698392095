import spacings from '@/assets/constants/spacings';
import colors from '@constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: ${spacings.xxs}px;
    background-color: ${colors.Black};
`;