import colors from '@constants/colors';
import spacings from '@constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100vh;
    border-right: 1px solid ${colors.LightGrey};
    padding-top: ${spacings.s}px;
    padding-bottom: ${spacings.s}px;
    padding-left: ${spacings.s}px;
    padding-right: ${spacings.s}px;
    background-color: ${colors.White};
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-top: ${spacings.xs}px;
    margin-bottom: ${spacings.xs}px;
    cursor: pointer;
`;