import {calculateSpacings, formatPrice} from '@/common/helpers';
import {Body, Subline} from '@components/text';
import colors from '@constants/colors';
import spacings from '@constants/spacings';
import {IGenericElement, ISpacings} from '../../types';
import {DetailWrapper, Wrapper} from './styled';
import {IOptionTile} from './types';

const OptionTile = ({
    children,
    style,
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
    onClick,
    selected,
    title,
    description,
    price,
    selectedPrice,
    image,
    icon,
    imageHeight,
}: IGenericElement & ISpacings & IOptionTile) => {
    return (
        <Wrapper onClick={onClick}>
            {image && (
                <img
                    src={image}
                    style={{
                        display: 'block',
                        borderTopLeftRadius: 2,
                        borderTopRightRadius: 2,
                        height: imageHeight || 150,
                        width: '100%',
                        objectFit: 'cover',
                    }}
                />
            )}
            <DetailWrapper
                style={{
                    ...(onClick ? {cursor: 'pointer'} : {}),
                    ...calculateSpacings({mt, mr, mb, ml, pt, pr, pb, pl}),
                    ...(selected
                        ? {color: colors.White, backgroundColor: colors.Black}
                        : {backgroundColor: colors.LightGrey}),
                    ...(image
                        ? {borderTopLeftRadius: 0, borderTopRightRadius: 0}
                        : {}),
                    ...style,
                }}>
                {icon && (
                    <div
                        style={{
                            marginBottom: spacings.xxxs,
                        }}>
                        {selected ? icon.active : icon.inactive}
                    </div>
                )}
                {image || icon ? (
                    <Body>{title}</Body>
                ) : (
                    <Subline quaternary>{title}</Subline>
                )}
                {description && <Body>{description}</Body>}
                {(selectedPrice || selectedPrice === 0) &&
                (price || price === 0) &&
                price - selectedPrice !== 0 ? (
                    <Body size="small">
                        {price - selectedPrice > 0 ? '+' : ''}
                        {formatPrice(price - selectedPrice)}
                    </Body>
                ) : null}
                {selected && (price || price === 0) && (
                    <Body size="small">{formatPrice(price)}</Body>
                )}
            </DetailWrapper>
        </Wrapper>
    );
};

export default OptionTile;
