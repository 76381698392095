import colors from '@constants/colors';
import spacings from '@constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
`;

export const DetailWrapper = styled.div`
    padding: ${spacings.xxs}px;
    min-width: 150px;
    border-radius: 2px;
    flex: 1;
    box-shadow: 0px 4px 0px 0px ${colors.Grey};
`;
