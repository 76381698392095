import React from 'react';
import Configurator from './features/configurator';

function App() {
  return (
    <div className="App">
      <Configurator />
    </div>
  );
}

export default App;
