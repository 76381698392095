import React from 'react';
import {Wrapper} from './styled';
import Select from '@components/select';
import {
    BASE_OPTIONS,
    COLOR_OPTIONS,
    DELIVERY_OPTIONS,
    HEIGHT_OPTIONS,
    MAST_OPTIONS,
    MONTAGE_OPTIONS,
    ROTOR_OPTIONS,
} from '@constants/mock';
import spacings from '@constants/spacings';
import Slider from '@components/slider';
import ColorPicker from '@components/color-picker';
import {Subline} from '@components/text';

const ProductPanel = () => {
    return (
        <Wrapper>
            <Select
                title="Rotor"
                decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                options={ROTOR_OPTIONS}
                selected={'s'}
                mb={spacings.l}
            />
            <Slider
                title="Masthöhe (in m)"
                decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                options={HEIGHT_OPTIONS}
                selectedIndex={3}
                mb={spacings.l}
            />
            <Select
                title="Masttyp"
                decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                options={MAST_OPTIONS}
                selected={'carbonmast'}
                mb={spacings.l}
            />
            <Select
                title="Fundament"
                decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                options={BASE_OPTIONS}
                imageSize="small"
                selected={'beton'}
                mb={spacings.l}
            />
            <div>
                <Subline quaternary style={{lineHeight: 1}} mb={spacings.s}>
                    Farben
                </Subline>
                <ColorPicker
                    title="Rotor"
                    decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                    options={COLOR_OPTIONS}
                    selected={'transparent'}
                    mb={spacings.l}
                />
                <ColorPicker
                    title="Mast"
                    decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                    options={COLOR_OPTIONS}
                    selected={'transparent'}
                    mb={spacings.l}
                />
                <ColorPicker
                    title="Fundament"
                    decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                    options={COLOR_OPTIONS}
                    selected={'transparent'}
                    mb={spacings.l}
                />
            </div>
            <Select
                title="Montage"
                decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                options={MONTAGE_OPTIONS}
                imageSize="small"
                selected={'standard'}
                mb={spacings.l}
            />
            <Select
                title="Versand"
                decription="Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipis cing elit."
                options={DELIVERY_OPTIONS}
                imageSize="small"
                selected={'paket'}
            />
        </Wrapper>
    );
};

export default ProductPanel;
