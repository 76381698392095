import React from 'react';
import {Wrapper} from './styled';

const PreviewPanel = () => {
    return (
        <Wrapper>
            <img src={require('@images/sample.png')} style={{height: '70%'}} />
        </Wrapper>
    );
};

export default PreviewPanel;
