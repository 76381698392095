import {IOption, ISpacings} from './types';

export const calculateSpacings = ({
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
}: ISpacings) => {
    return {
        ...(mt ? {marginTop: mt} : {}),
        ...(mr ? {marginRight: mr} : {}),
        ...(mb ? {marginBottom: mb} : {}),
        ...(ml ? {marginLeft: ml} : {}),
        ...(pt ? {paddingTop: pt} : {}),
        ...(pr ? {paddingRight: pr} : {}),
        ...(pb ? {paddingBottom: pb} : {}),
        ...(pl ? {paddingLeft: pl} : {}),
    };
};

export const formatPrice = (
    price: number,
    currency = 'EUR',
    country = 'de-DE',
) => {
    return new Intl.NumberFormat(country, {
        style: 'currency',
        currency: currency,
    }).format(price);
};
