import colors from '@constants/colors';
import spacings from '@constants/spacings';
import styled from 'styled-components';

export const ColorTile = styled.div`
    position: relative;
    width: 49px;
    height: 49px;
    border: 1px solid ${colors.Grey};
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 4px;
`;

export const DiagonaleLine = styled.div`
    position: absolute;
    top: calc(50% - 1px);
    height: 2px;
    width: 100%;
    background-color: ${colors.MiddleGrey};
    z-index: 0;
    transform: skewY(45deg);
`;

export const DiagonaleLineAlt = styled.div`
    position: absolute;
    top: calc(50% - 1px);
    height: 2px;
    width: 100%;
    background-color: ${colors.MiddleGrey};
    z-index: 0;
    transform: skewY(-45deg);
`;
