import {ReactComponent as ICMontage} from '@icons/ic-montage.svg';
import {ReactComponent as ICMontageWhite} from '@icons/ic-montage-white.svg';
import {ReactComponent as ICSelfBuilt} from '@icons/ic-selfbuilt.svg';
import {ReactComponent as ICSelfBuiltWhite} from '@icons/ic-selfbuilt-white.svg';
import {ReactComponent as ICPartSelfBuilt} from '@icons/ic-partselfbuilt.svg';
import {ReactComponent as ICPartSelfBuiltWhite} from '@icons/ic-partselfbuilt-white.svg';
import {ReactComponent as ICPackage} from '@icons/ic-package.svg';
import {ReactComponent as ICPackageWhite} from '@icons/ic-package-white.svg';
import {ReactComponent as ICSpedition} from '@icons/ic-spedition.svg';
import {ReactComponent as ICSpeditionWhite} from '@icons/ic-spedition-white.svg';
import {ReactComponent as ICTeam} from '@icons/ic-team.svg';
import {ReactComponent as ICTeamWhite} from '@icons/ic-team-white.svg';

export const ROTOR_OPTIONS = [
    {name: 'S', value: 's', description: 'Leistung 1kW', price: 1000},
    {name: 'M', value: 'm', description: 'Leistung 2kW', price: 3000},
    {name: 'L', value: 'l', description: 'Leistung 3kW', price: 5000},
    {name: 'XL', value: 'xl', description: 'Leistung 4kW', price: 10000},
];

export const MAST_OPTIONS = [
    {
        name: 'Holzmast komposit',
        value: 'carbonmast',
        description: '',
        price: 1000,
        image: require('@images/composit.png'),
    },
    {
        name: 'Stahlmast',
        value: 'stahlmast',
        description: '',
        price: 2500,
        image: require('@images/steel-1.png'),
    },
    {
        name: 'Stahlmast konisch',
        value: 'stahlmast_conically',
        description: '',
        price: 2500,
        image: require('@images/steel-conically-1.png'),
    },
];

export const BASE_OPTIONS = [
    {
        name: 'Beton leicht',
        value: 'beton',
        description: '',
        price: 4075,
        image: require('@images/beton-leicht-1.png'),
    },
    {
        name: 'Beton schwer',
        value: 'beton_schwer',
        description: '',
        price: 2350,
        image: require('@images/beton-schwer-1.png'),
    },
    {
        name: 'Beton dekorativ',
        value: 'beton_deko',
        description: '',
        price: 7000,
        image: require('@images/beton-dekorativ-2.png'),
    },
    {
        name: 'Mastadapter',
        value: 'mastadpater',
        description: '',
        price: 1000,
        image: require('@images/mastadapter.png'),
    },
];

export const MONTAGE_OPTIONS = [
    {
        name: 'Standard',
        value: 'standard',
        description: '',
        price: 1000,
        icon: {
            active: <ICMontageWhite />,
            inactive: <ICMontage />,
        },
    },
    {
        name: 'Teilselbstbau',
        value: 'teilselbstbau',
        description: '',
        price: 50,
        icon: {
            active: <ICSelfBuiltWhite />,
            inactive: <ICSelfBuilt />,
        },
    },
    {
        name: 'Selbstbausatz',
        value: 'selbstbausatz',
        description: '',
        price: 0,
        icon: {
            active: <ICPartSelfBuiltWhite />,
            inactive: <ICPartSelfBuilt />,
        },
    },
];

export const DELIVERY_OPTIONS = [
    {
        name: 'Paket',
        value: 'paket',
        description: '',
        price: 250,
        icon: {
            active: <ICPackageWhite />,
            inactive: <ICPackage />,
        },
    },
    {
        name: 'Spedition',
        value: 'spedition',
        description: '',
        price: 1000,
        icon: {
            active: <ICSpeditionWhite />,
            inactive: <ICSpedition />,
        },
    },
    {
        name: 'Montageteam',
        value: 'montageteam',
        description: '',
        price: 2000,
        icon: {
            active: <ICTeamWhite />,
            inactive: <ICTeam />,
        },
    },
];

export const HEIGHT_OPTIONS = [
    {name: '0', value: 0, description: ''},
    {name: '6', value: 6, description: ''},
    {name: '9', value: 9, description: ''},
    {name: '12', value: 12, description: ''},
    {name: '15', value: 15, description: ''},
    {name: '18', value: 18, description: ''},
    {name: '24', value: 24, description: ''},
    {name: '30', value: 30, description: ''},
];

export const COLOR_OPTIONS = [
    {name: 'Keine Farbe', value: 'transparent', price: 0},
    {name: 'Signalrot', value: '#e71818', price: 250},
    {name: 'Dunkel Braun', value: '#6a5050', price: 500},
    {name: 'Aqua Blau', value: '#31f5eb', price: 350},
    {name: 'Wald Grün', value: '#279b31', price: 250},
    {name: 'Gelb', value: '#ffc524', price: 500},
    {name: 'Velvet Pink', value: '#a81c9c', price: 700},
    {name: 'Deep Blue', value: '#120959', price: 1200},
    {name: 'Zart Grün', value: '#c4f89d', price: 400},
];
